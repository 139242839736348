import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../views/shared/layout/layout";
import { SEO } from '../../components/SEO/SEO';
import Hero from "../../views/shared/hero/Hero";
import img from "../../assets/images/hero-home.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout>
        <SEO title="Política de cookies | Obelion" description="Política de cookies de Obelion P&amp;W S.L." robots="noindex nofollow" />
        <Hero title={"Política de cookies"} photo={img} />
        <div className="docs">
            {children}
        </div>
    </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`La página web utiliza cookies cuando un usuario navega por la misma, las cookies son ficheros enviados a un navegador por medio de un servidor web para registrar las actividades del usuario y permitirle una navegación más fluida y personalizada.`}</p>
    <h2>{`TIPOS DE COOKIES`}</h2>
    <p>{`Los tipos de cookies que utiliza esta página web son los siguientes:`}</p>
    <ul>
      <li parentName="ul">{`Cookies propias: Son aquéllas cookies que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.`}</li>
      <li parentName="ul">{`Cookies de sesión: Este tipo de cookies están diseñadas para recabar y almacenar datos mientras el usuario accede a una página web.`}</li>
      <li parentName="ul">{`Cookies de tercero: Son aquellas cookies que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.`}</li>
      <li parentName="ul">{`Cookies de análisis: Son las cookies que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado.`}</li>
    </ul>
    <h2>{`REVOCACIÓN Y ELIMINACIÓN DE COOKIES`}</h2>
    <p>{`Usted como usuario tiene la posibilidad de configurar su navegador para impedir la entrada de cookies, bloquearlas o, en su caso, eliminarlas. En el caso de que usted como usuario decida bloquearlas, Indra no garantiza el correcto funcionamiento de los servicios prestados en este sitio.`}</p>
    <p>{`Los pasos para acceder al menú de configuración de las cookies de los navegadores principales son:`}</p>
    <ul>
      <li parentName="ul">{`Internet Explorer: Herramientas →Opciones de Internet →Privacidad →Configuración`}</li>
      <li parentName="ul">{`Google Chrome: Configuración →Mostrar opciones avanzadas →Privacidad →Configuración de contenido`}</li>
      <li parentName="ul">{`Firefox: Herramientas →Opciones →Privacidad →Historial →Configuración Personalizada`}</li>
      <li parentName="ul">{`Safari: Preferencias →Seguridad`}</li>
    </ul>
    <p>{`Para más información al respecto consulte la ayuda de su navegador.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      